.typewriter {
    position: absolute;
    font-family: sans-serif;
    font-weight:1000;
    font-size:calc(14rem + 1.5vw );
    text-align: center;
    display:flex;
    justify-content:center;
    align-items:center;
    color:#ffffff;
    height:0;
  }
